import React, { useState, useEffect } from "react"
import Layout from "./../../components/layout"
import SEO from "./../../components/seo"
import Img from "gatsby-image"
import { OutboundLink } from "gatsby-plugin-google-gtag"


//import css
import "./../../styles/free-tools.css"
import "./../../styles/products.css"
//import components
import GetStartedBanner from "./../../components/get-started-footer"

const FreeTools = ({ data }) => (
  <Layout>
    <SEO
      title="Check Website Uptime - Odown Free Tools"
      description="A free website uptime tool to test the availability and performance of a website from multiple locations globally."
      pathname={`/free-tools/website-uptime/`}
      image={{
        src: "/general-image-og--website_uptime_checker.jpg",
        width: 1600,
        height: 800,
      }}
    />
    {/* HEADER  */}
    <HeadingSection />
    <FreeTools_section />
    {/* Content Section */}
    <WebsiteUptimeSection />
    {/* CheckWebsiteUptime*/}
    < CheckWebsiteUptime />
    {/* Key Features Section */}
    <KeyFeaturesSection />
    {/* How to use section*/}
    < Howtouse />
    {/* Advantages of Uptime Checker section*/}
    < Advantages />
    {/*Track your website section*/}
    <TrackyourWebsiteSection />

    {/* Get started banner */}
    <GetStartedBanner />
  </Layout>
)

/**
 *
 * @param {*} param0
 * @returns
 */
const HeadingSection = ({ data }) => {
  return (
    <div className="u-features freetools-hero">
      <div className="container">
        <h1 className="u-features__heading-title">
          Instant Website Uptime Check — 100% Free
        </h1>
        <div className="FreeTool-heading__description">
          Odown's free uptime tool keeps an eye on your website so it's always up for your users. Gain instant insights into your website's availability and performance from various locations worldwide.        </div>
      </div>
      <div className="FreeTool-heading__button">
        <OutboundLink
          className="btn-primary"
          eventCategory="Launch the Odown App"
          eventAction="Click"
          eventLabel="Goto signup"
          href="https://app.odown.io/signup"
        >
          Trial Odown free for 14 days
        </OutboundLink>
      </div>
    </div>
  )
}

const FreeTools_section = () => {
    const [pingsRegionsResults, setPingsRegionsResults] = useState({
      NVirginia: { ok: null, resolveTime: 0, connectionTime: 0, downloadTime: 0, responseTime: 0 },
      London: {ok: null, resolveTime: 0, connectionTime: 0, downloadTime: 0, responseTime: 0},
      CTown: { ok: null, resolveTime: 0, connectionTime: 0, downloadTime: 0, responseTime: 0 },
      Sydney: { ok: null, resolveTime: 0, connectionTime: 0, downloadTime: 0, responseTime: 0 },
      Bahrain: { ok: null, resolveTime: 0, connectionTime: 0, downloadTime: 0, responseTime: 0 },
      Singapore: { ok: null, resolveTime: 0, connectionTime: 0, downloadTime: 0, responseTime: 0 }, 
      SPaulo: { ok: null, resolveTime: 0, connectionTime: 0, downloadTime: 0, responseTime: 0 },
    });
  
  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [error, setError] = useState(null);
  const [testUrl, setTestUrl] = useState("");
  const [region, setRegion] = useState('ALL_REGIONS');
  const [countdown, setCountdown] = useState(0);
  const [resultsDisplayed, setResultsDisplayed] = useState(false);
  const [regionsLoadedCount, setRegionsLoadedCount] = useState(0);



  const handleInputChange = (event) => {
    setTestUrl(event.target.value);
  };

  const handleRegionChange = (e) => {
    setRegion(e.target.value);
  };

  const getRegionName = (region) => {
    const regionMap = {
      NVirginia: "North Virginia",
      NCalifornia: "North California",
      CTown: "Cape Town",
      HKong: "Hong Kong",
      SPaulo: "Sao Paulo"
    };
    return regionMap[region] || region;
  }

  const handleStartTest = async () => {
    if (!testUrl) {
      setError("Please enter a valid URL");
      return;
    }

    setLoading(true);
    setDisableButton(true);
    setError(null);
    setCountdown(0);
    setResultsDisplayed(false);
    setRegionsLoadedCount(0);
    setPingsRegionsResults({}); 

    try {
      const response = await fetch(`https://odown-regions-ping.regionsping.workers.dev?url=${testUrl}&region=${region}`);
      if (response.status === 401) {
        throw new Error("Authentication failed");
      }
      if (response.status === 429) {
        throw new Error("Rate limit exceeded");
      }
      if (response.status === 408) {
        throw new Error("Request timed out");
      }
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const pingRegions = await response.json();
  
      if (region === 'ALL_REGIONS') {
        const regions = Object.keys(pingRegions);
        regions.forEach((region, index) => {
          setTimeout(() => {
            setPingsRegionsResults(prevResults => ({
              ...prevResults,
              [region]: pingRegions[region]
            })); 
            setRegionsLoadedCount(prevCount => prevCount + 1);

            if (index === regions.length - 1) {
              setResultsDisplayed(true);
              setCountdown(5);
            }
          }, index * 500); 
         });
      } else {
        setPingsRegionsResults(pingRegions);
        setResultsDisplayed(true); 
        setCountdown(5); 
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    }
  
    if (countdown === 0 && disableButton && resultsDisplayed) {
      setDisableButton(false);
      setResultsDisplayed(false); 
    }
  }, [countdown, disableButton, resultsDisplayed]);

  return (
    <section id="freeTools" className="u-features freetools-list">
      <div className="wrapperContainer">
        <div className="free-ping-test">
          <span className="test-label">I want to test</span>
          <input
            type="text"
            value={testUrl}
            onChange={handleInputChange}
            placeholder="https://www.google.com/"
            className="test-url-input"
          />
          <span className="test-label">from</span>
          <select className="region-select" value={region} onChange={handleRegionChange}>
            <option value="ALL_REGIONS">All Regions</option>
            <option value="NORTH_AMERICA">North America</option>
            <option value="EUROPE">Europe</option>
            <option value="AFRICA">Africa</option>
            <option value="ASIA">Asia</option>
            <option value="AUSTRALIA">Australia</option>
            <option value="SOUTH_AMERICA">South America</option>
            <option value="MIDDLE_EAST">Middle East</option>
          </select>
          <button
          className={`start-test-button ${countdown > 0 ? 'countdown-active' : ''} ${disableButton || loading || countdown > 0 ? 'button-disabled' : ''}`}
          onClick={handleStartTest}
          disabled={disableButton || loading || countdown > 0} 
          >
          {countdown > 0 ? `${countdown}s` : "Check Uptime"}
          </button>
        </div>

        {loading && <div>Loading results...</div>}
        {error && <div>Error: {error}</div>}
        {!loading && !error && Object.keys(pingsRegionsResults).length > 0 && (
          <div className="regions-displayed">
            {Object.keys(pingsRegionsResults).map((region, index) => (
              <div key={index} className={`ping-item_wrapper ${pingsRegionsResults[region].ok === null ? "greyed-out" : pingsRegionsResults[region].ok ? "available" : "unavailable"}`}>
                <label className="ping-item">
                  <div className="ping-header">
                    <div className={`ping-region ${pingsRegionsResults[region].ok === null ? "greyed-out" : pingsRegionsResults[region].ok ? "success" : "error"}`}>
                      <span className="status-icon">
                        {pingsRegionsResults[region].ok === null ? '✔' : pingsRegionsResults[region].ok ? '✔️' : '❌'}
                      </span>
                      <strong>{getRegionName(region)}</strong>
                    </div>
                    <div className="ping-status">
                      <p className={pingsRegionsResults[region].ok === null ? "unknown-text" : pingsRegionsResults[region].ok ? "available-text" : "unavailable-text"}>
                        {pingsRegionsResults[region].ok === null ? "Unknown" : pingsRegionsResults[region].ok ? "Website is available" : "Website is unavailable"}</p>
                    </div>
                  </div>
                  {pingsRegionsResults[region].ok !== null && (
                  <div className="ping-info">
                    <p>
                      <div className="resolve-box"></div>
                      <strong>Resolve Time:</strong> {pingsRegionsResults[region].resolveTime} seconds
                    </p>
                    <p>
                      <div className="connection-box"></div>
                      <strong>Connection Time:</strong> {pingsRegionsResults[region].connectionTime} seconds
                    </p>
                    <p>
                      <div className="download-box"></div>
                      <strong>Download Time:</strong> {pingsRegionsResults[region].downloadTime} seconds
                    </p>
                    <hr />
                    <p><strong>Total Response Time:</strong> {pingsRegionsResults[region].responseTime} seconds</p>
                  </div>
                  )}
                  <div className="ping-time-time">
                    <div className="ping-bar-container">
                      <div
                        className="ping-bar"
                        style={{ width: `${Math.min((pingsRegionsResults[region].responseTime / 1) * 100, 100)}%` }}
                      ></div>
                    </div>
                    <strong>{pingsRegionsResults[region].responseTime}s</strong>
                  </div>
                </label>
              </div>

            ))}
          </div>
        )}
      </div>
    </section>
  );
};

const WebsiteUptimeSection = () => {
  return (
    <section className="Website-uptime-section">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <h2 className="content-title1">
              What Is Website Uptime?</h2>
            <p className="content-desc2">
              Uptime or website availability refers to the time during which your website is accessible to visitors. Website uptime is often measured as a percentage of time periods during which your website is available.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

const CheckWebsiteUptime = () => {
  return (
    <section className="check-website-uptime">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <h2 className="uptime-title1">
              Why Check Your Website Uptime?</h2>
            <p className="content-desc2">
              If your website is down, you risk losing customers and damaging your brand. Odown's Uptime Checker helps you stay proactive and resolve issues quickly.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

const KeyFeaturesSection = () => {
  const features = [
    {
      title: "Global Monitoring",
      description: " Check uptime from multiple locations worldwide to make sure users everywhere can access your site.",
    },
    {
      title: "Instant Results",
      description: "See in real-time what happens to your updated website, be it response times, or downtimes.",
    },
    {
      title: "Easy to Use & Simple",
      description: "Just fill in the URL of your website, and voilà. The detailed work is by our tool.",
    },
    {
      title: "Mobile-Friendly",
      description: " Get uptime data anywhere, anytime, either sitting on your desk or on the move.",
    },
    {
      title: "Free Forever",
      description: "Get all these features without paying any extra cost. Our uptime tool comes for free to be used by everyone.",
    },
  ];

  return (
    <section className="u-advanced-features">
      <div className="container">
        <h2 className="content-title1">Key Features</h2>
        <div className="u-advanced-features__items">
          <ul>
            {features.map((feature, index) => (
              <li key={index}>
                <div className="u-a-features__item-wrapper">
                  <div className="u-a-features__item--title">
                    {feature.title}
                  </div>
                  <div className="u-a-features__item--desc">
                    {feature.description}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

const Howtouse = () => {
  return (
    <section className="Website-uptime-section">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <h2 className="content-title1">How to use:</h2>
            <p className="use-desc2">
              1. Please type your website address in the above search box:
            </p>
            <p className="use-desc2">
              2. Click "Check Uptime" to run immediately on multiple servers.
            </p>
            <p className="use-desc2">
              3. The detailed output of whether your website is up or down will be seen below.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

const Advantages = () => {
  return (
    <section className="Website-uptime-section">
      <div className="container">
        <h2 className="content-title1">Advantages of Odown Uptime Checker:</h2>
        <div className="advantages-wrapper">
          <div className="advantage-item">
            <h3 className="advantage-title">Improve confidence for customers:</h3>
            <p className="use-desc2">
              Your website is always accessible to the customer.
            </p>
          </div>
          <div className="advantage-item">
            <h3 className="advantage-title">Improvements in Response:</h3>
            <p className="use-desc2">
              Identify probable bottlenecks or slowdowns by checking response times.
            </p>
          </div>
          <div className="advantage-item">
            <h3 className="advantage-title">Stay ahead of issues:</h3>
            <p className="use-desc2">
              Receive data from multiple locations to avoid a single region's downtime affecting your business.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

const TrackyourWebsiteSection = () => {
  return (
    <section className="Website-uptime-section">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <h2 className="content-title1">
              Track your website's uptime with Website Monitoring by Odown</h2>
            <p className="content-desc2">
              Much more than website monitoring, in-depth availability monitoring lets you keep track of all the many moving parts in your web presence. API and web application monitoring complete the interactions. Try it all free for 14 days, no credit card or commitment required.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FreeTools
